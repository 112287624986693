import React from 'react'

import TestimonialSlider from 'components/TestimonialSlider'

const ClientTestimonials = () => (
  <TestimonialSlider
    testimonials={[
      {
        name: 'Ysaël Pépin',
        role: 'IT Development Manager, Aldo',
        quote:
          'We chose Volume7 as our digital innovation partner because of their multidisciplinary offering and ability to deliver quickly.',
      },
      {
        name: 'Thierry Skoda',
        role: 'Co-Founder & CTO, Usewalter',
        quote:
          "Volume7 brings a rare mix of business acumen and technical expertise to the table. They're my go-to software development agency.",
      },
      {
        name: 'Grant Harris',
        role: 'VP of Design, Beamery',
        quote:
          'Volume7 is my go-to agency for quality work, rapid execution, while always bringing an efficient and pleasant partnership to the table.',
      },
      {
        name: 'Paul Kesserwani',
        role: 'Co-Founder & CEO, Cushion',
        quote:
          "We're really happy with the work you guys have done. The app looks great, and the codebase has been set up very well for future maintenance.",
      },
    ]}
  />
)

export default ClientTestimonials
