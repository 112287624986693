import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { StaticImage } from 'gatsby-plugin-image'

import { largerThan, smallerThan } from 'styles/media'
import { white, black } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { cover } from 'styles/helpers'
import { borderRadius, animationTimes, animationCurve } from 'styles/variables'
import { boxShadow } from 'styles/helpers'

import Button from 'components/button/Button'
import Heading from 'components/Heading'
import RichText from 'components/RichText'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  text-align: left;
  position: relative;
  overflow: hidden;
  border-radius: ${spacing.scale(borderRadius, 1.25)};
  background-color: ${white};
  transition: box-shadow ${animationTimes.base} ${animationCurve},
    transform ${animationTimes.base} ${animationCurve};
  transform: translate3d(0, 0, 0);
  margin-top: ${spacing.xLarge};
  ${boxShadow.small};

  ${largerThan.small`
    flex-direction: row;
    margin-top: 2.75rem;
  `};

  ${largerThan.medium`
    flex-direction: column;
    width: 280px;
    margin-top: 0;
    margin-left: ${spacing.xxLarge};
  `};

  ${largerThan.large`
    width: 320px;
    margin-left: ${spacing.xxxLarge};
  `};

  ${largerThan.xLarge`
    width: 30%;
    margin-left: 5rem;
  `};

  &:after {
    content: '';
    box-shadow: inset 0 0 0 1px ${rgba(black, 0.13)};
    z-index: 10;
    border-radius: ${spacing.scale(borderRadius, 1.25)};
    pointer-events: none;
    ${cover('absolute')};
  }
`

const Header = styled.div`
  position: relative;
  height: 140px;
  flex-shrink: 0;

  ${largerThan.small`
    height: auto;
    width: 30%;
  `};

  ${largerThan.medium`
    height: 200px;
    width: 100%;
  `};

  ${largerThan.large`
    height: 210px;
  `};

  ${largerThan.xLarge`
    height: 230px;
  `};
`

const FigureWrap = styled.div`
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  ${cover('absolute')};

  > * {
    ${cover('absolute')};
    object-fit: cover;
    mix-blend-mode: multiply;
    filter: contrast(0.85);
  }
`

const Overlay = styled.div`
  background-image: linear-gradient(0deg, ${white} 0%, ${rgba(white, 0)} 100%);
  z-index: 10;
  ${cover('absolute')};

  ${largerThan.small`
    background-image: linear-gradient(-90deg, ${white} 0%, ${rgba(
    white,
    0
  )} 100%);
  `};

  ${largerThan.medium`
    background-image: linear-gradient(0deg, ${white} 0%, ${rgba(
    white,
    0
  )} 100%);
  `};
`

const Content = styled.div`
  position: relative;
  z-index: 10;
  padding: ${spacing.large};
  margin-top: -${spacing.xLarge};

  ${smallerThan.small`
    padding-bottom: ${spacing.xLarge};
  `};

  ${largerThan.small`
    margin-top: 0;
    margin-left: -${spacing.xxLarge};
  `};

  ${largerThan.medium`
    padding: ${spacing.xLarge};
    margin-top: -${spacing.xxLarge};
    margin-left: 0;
  `};

  ${largerThan.large`
    margin-top: -${spacing.xxxLarge};
  `};

  ${largerThan.xLarge`
    padding: ${spacing.xxLarge};
    padding-bottom: 3rem;
    margin-top: -4rem;
  `};
`

const CareersSidebar = () => (
  <Container>
    <Header>
      <FigureWrap>
        <StaticImage
          src="../../assets/images/about/meeting.jpg"
          layout="fullWidth"
          alt="Alt text"
          placeholder="blurred"
        />
      </FigureWrap>
      <Overlay />
    </Header>
    <Content>
      <Heading size={4}>Looking for a new challenge?</Heading>
      <RichText>
        <p>
          We're looking for hard-working and passionate self-starters to help us
          build outstanding digital products.
        </p>
        <Button to="/careers/" size="small">
          View careers
        </Button>
      </RichText>
    </Content>
  </Container>
)

export default CareersSidebar
