import React from 'react'
import { ThemeProvider } from 'styled-components'

import { themes } from 'styles/theme'

import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import RichText from 'components/RichText'
import Heading from 'components/Heading'
import LogoWall from 'components/about/LogoWall'
import ClientTestimonials from 'components/misc/ClientTestimonials'

const Clients = () => (
  <ThemeProvider theme={themes.dark}>
    <Section>
      <SectionBlock>
        <Wrapper size="narrow">
          <Heading size={2}>Past clients and experience</Heading>
          <RichText>
            <p>
              Here are just a few of the companies, past and present, that trust
              us with their business.
            </p>
          </RichText>
        </Wrapper>
      </SectionBlock>
      <SectionBlock>
        <Wrapper>
          <LogoWall
            logos={[
              'affirm',
              'aldo',
              'bat',
              'beamery',
              'bell',
              'consensys',
              'creditkarma',
              'desjardins',
              'plenty',
              'qbio',
              'salesforce',
              'seatgeek',
              'sentilink',
              'titan',
              'yellow-pages',
              'zendesk',
            ]}
          />
        </Wrapper>
      </SectionBlock>
      <SectionBlock>
        <Wrapper>
          <ClientTestimonials />
        </Wrapper>
      </SectionBlock>
    </Section>
  </ThemeProvider>
)

export default Clients
