import React from 'react'
import styled from 'styled-components'

import { widowFix } from 'utils/string'

import { cover } from 'styles/helpers'
import { borderRadius } from 'styles/variables'
import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import RichText from 'components/RichText'

const Container = styled.div`
  position: static;

  ${largerThan.xSmall`
    display: flex;
    align-items: flex-start;
  `};

  ${largerThan.small`
    display: block;
  `};
`

const PhotoWrap = styled.div`
  display: block;
  flex-shrink: 0;
  position: relative;
  border-radius: ${spacing.scale(borderRadius, 1.25)};
  overflow: hidden;
  margin-bottom: ${spacing.medium};
  transform: translate3d(0, 0, 0);

  > * {
    ${cover('absolute')};
  }

  ${largerThan.xSmall`
    width: 80px;
    margin-right: ${spacing.medium};
  `};

  ${largerThan.small`
    width: auto;
    margin-right: 0;
  `};

  ${largerThan.large`
    margin-bottom: ${spacing.large};
  `};

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

const Content = styled.div`
  position: static;
`

const Main = styled.div`
  margin-bottom: ${spacing.scale(spacing.small, 1.5)};

  ${largerThan.medium`
    margin-bottom: ${spacing.medium};
  `};
`

const Name = styled(Heading)`
  margin-bottom: 0;

  ${largerThan.medium`
    margin-bottom: ${spacing.tiny};
  `};
`

const Role = styled.span`
  display: block;
`

const Bio = styled.p`
  font-size: 0.9em;

  ${largerThan.small`
    padding-right: ${spacing.medium};
  `};

  ${largerThan.medium`
    padding-right: ${spacing.large};
  `};

  ${largerThan.large`
    padding-right: ${spacing.xLarge};
  `};
`

const TeamMember = ({ name, bio, role, photo }) => (
  <Container>
    <PhotoWrap>{photo}</PhotoWrap>
    <Content>
      <Main>
        <Name size={4} tag="h3">
          {name}
        </Name>
        <Role>{role}</Role>
      </Main>
      <RichText>
        <Bio>{widowFix(bio)}</Bio>
      </RichText>
    </Content>
  </Container>
)

export default TeamMember
