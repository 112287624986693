import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import debounce from 'lodash/debounce'

import { borderRadius, animationTimes, animationCurve } from 'styles/variables'
import { cover } from 'styles/helpers'
import * as spacing from 'styles/spacing'
import { smallerThan, largerThan } from 'styles/media'

const PHOTO_HEIGHT = '100%'

const Container = styled.div`
  margin-left: -${spacing.large};
  margin-right: -${spacing.large};

  ${largerThan.small`
    margin-left: -${spacing.xxLarge};
    margin-right: -${spacing.xxLarge};
  `};

  ${largerThan.medium`
    margin-left: 0;
    margin-right: 0;
  `};

  ${smallerThan.medium`
    position: relative;
    
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 20%;
      pointer-events: none;
      z-index: 10;
      transition: opacity ${animationTimes.base} ${animationCurve};
    }

    &:before {
      left: 0;
      background-image: linear-gradient(
        90deg,
        ${({ theme }) => theme.background} 0%,
        ${({ theme }) => rgba(theme.background, 0)} 100%
      );
      opacity: ${(props) => (props.isAtBeginning ? '0' : '1')};
    }

    &:after {
      right: 0;
      background-image: linear-gradient(
        -90deg,
        ${({ theme }) => theme.background} 0%,
        ${({ theme }) => rgba(theme.background, 0)} 100%
      );
      opacity: ${(props) => (props.isAtEnd ? '0' : '1')};
    }
  `};
`

const Inner = styled.div`
  position: static;

  ${smallerThan.medium`
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  `};

  ${largerThan.medium`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${spacing.large};
    grid-row-gap: ${spacing.large};
  `};
`

const Photo = styled.div`
  position: relative;
  border-radius: ${borderRadius};
  overflow: hidden;
  margin-right: ${spacing.medium};
  width: 55%;
  transform: translate3d(0, 0, 0);

  > * {
    ${cover('absolute')};
  }

  ${largerThan.xSmall`
    width: 50%;
  `};

  ${largerThan.small`
    width: 38%;
    margin-right: ${spacing.large};
  `};

  &:first-child {
    margin-left: ${spacing.large};

    ${largerThan.small`
      margin-left: ${spacing.xxLarge};
    `};

    ${largerThan.medium`
      margin-left: 0;
    `};
  }

  &:last-child {
    margin-right: ${spacing.large};

    ${largerThan.small`
      margin-right: ${spacing.xxLarge};
    `};

    ${largerThan.medium`
      margin-right: 0;
    `};
  }

  ${smallerThan.medium`
    display: inline-block;
    flex-shrink: 0;
    scroll-snap-align: center;
  `};

  ${largerThan.medium`
    margin: 0;
    width: auto;
  `};

  &:after {
    display: block;
    content: '';
    padding-bottom: ${PHOTO_HEIGHT};
  }

  ${(props) =>
    props.large &&
    css`
      position: relative;

      ${largerThan.medium`
        grid-column: span 2;
      `};

      &:after {
        ${largerThan.medium`
          padding-bottom: calc(
            (${PHOTO_HEIGHT} / 2) - ${spacing.scale(spacing.small, 1.5)}
          );
        `};
      }
    `}
`

const PhotoGrid = ({ photos }) => {
  const [isAtBeginning, setIsAtBeginning] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)

  const scrollRef = useCallback((scrollNode) => {
    if (!scrollNode) return

    const handleScroll = debounce((e) => {
      if (e.target.scrollLeft <= 15) {
        setIsAtBeginning(true)
      } else {
        setIsAtBeginning(false)
      }

      if (
        e.target.scrollLeft + 15 >=
        e.target.scrollWidth - e.target.getBoundingClientRect().width
      ) {
        setIsAtEnd(true)
      } else {
        setIsAtEnd(false)
      }
    }, 1)

    scrollNode.addEventListener('scroll', handleScroll)

    return () => {
      if (scrollNode) scrollNode.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Container isAtBeginning={isAtBeginning} isAtEnd={isAtEnd}>
      <Inner ref={scrollRef}>
        {photos.map((photo, i) => (
          <Photo key={i} large={photo.large}>
            {photo.photo}
          </Photo>
        ))}
      </Inner>
    </Container>
  )
}

export default PhotoGrid
