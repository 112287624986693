import React from 'react'
import styled from 'styled-components'
import { hideVisually, stripUnit, rgba } from 'polished'

import { INFO_FOR_PROJECT_NAME } from 'utils/constants'

import { white, black } from 'styles/colors'
import { borderRadius, animationTimes, animationCurve } from 'styles/variables'
import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

const SCALE_FACTOR = 280 /* The larger, the smaller the logo will appear */

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${spacing.medium};
  grid-row-gap: ${spacing.medium};
  grid-auto-flow: dense;

  ${largerThan.small`
    grid-template-columns: repeat(3, 1fr);
  `};

  ${largerThan.medium`
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${spacing.large};
    grid-row-gap: ${spacing.large};
  `};
`

const Item = styled.a`
  position: relative;
  display: block;
  border-radius: ${spacing.scale(borderRadius, 1.25)};
  background-color: ${rgba(black, 0.12)};
  transition: background-color ${animationTimes.base} ${animationCurve};

  &:hover {
    background-color: ${rgba(black, 0.18)};
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 55%;
  }
`

const Logo = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: ${(props) => (stripUnit(props.logoWidth) / SCALE_FACTOR) * 100 + '%'};

  ${largerThan.medium`
    width: ${(props) => (stripUnit(props.logoWidth) / SCALE_FACTOR) * 90 + '%'};
  `};

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  *:not(.nofill) {
    fill: ${white};
  }
`

const Name = styled.span`
  ${hideVisually()};
`

const LogoWall = ({ logos }) => (
  <Container>
    {logos.map((logo, i) => {
      const handle = INFO_FOR_PROJECT_NAME[logo]

      return (
        <Item
          key={i}
          href={handle.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo logoWidth={handle.logoWidth}>{handle.logo}</Logo>
          <Name>{logo} logo</Name>
        </Item>
      )
    })}
  </Container>
)

export default LogoWall
