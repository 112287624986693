import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Heading from 'components/Heading'
import RichText from 'components/RichText'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import PhotoGrid from 'components/PhotoGrid'
import Stats from 'components/Stats'

const AboutUs = () => (
  <Section flushTop>
    <SectionBlock>
      <Wrapper size="narrow">
        <Heading size={2}>About us</Heading>
        <RichText>
          <p>
            We're a software development agency building digital products for
            enterprise and high-growth companies. While we're known to move
            quickly and deliver value out of fewer resources, we pride ourselves
            on building relationships that are long-lasting and mutually
            rewarding.
          </p>

          <p>
            What makes us tick is tight-knit collaboration with our clients and
            active involvement at every step of the process, enabling
            organizational change from within. Our multidisciplinary team has
            the expertise to handle strategy, design, and full-stack development
            for companies of all sizes.
          </p>

          <p>
            <Link to="/services/">View our services</Link>
          </p>
        </RichText>
      </Wrapper>
    </SectionBlock>
    <SectionBlock>
      <Wrapper>
        <PhotoGrid
          photos={[
            {
              photo: (
                <StaticImage
                  src="../../assets/images/about/grid/neon.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="100 emoji neon sign"
                />
              ),
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/about/grid/old-montreal.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Building with flowerpot in Old Montreal"
                />
              ),
              large: true,
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/about/grid/big-o.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Olympic stadium"
                />
              ),
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/about/grid/code.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Code editor"
                />
              ),
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/about/grid/books.jpg"
                  alt="Pile of books on table"
                />
              ),
            },
          ]}
        />
      </Wrapper>
    </SectionBlock>
    <SectionBlock>
      <Wrapper size="narrow">
        <Stats
          stats={[
            {
              number: '7',
              label: 'Years in business',
            },
            {
              number: '80+',
              label: 'Solutions deployed',
            },
            {
              number: '85%',
              label: 'Repeat business',
            },
          ]}
        />
      </Wrapper>
    </SectionBlock>
  </Section>
)

export default AboutUs
