import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import MetaTags from 'components/MetaTags'
import PageHeader from 'components/PageHeader'
import AboutUs from 'components/about/AboutUs'
import Leadership from 'components/about/Leadership'
import Clients from 'components/about/Clients'
import Principles from 'components/about/Principles'

const About = () => (
  <>
    <MetaTags title="About | Custom software development in Montreal | Volume7" />
    <PageHeader
      heading={
        <>
          Get to <span>know us</span>
        </>
      }
      description="We're a Montreal-based software development agency who's been building digital products since 2016."
      figure={
        <StaticImage
          src="../assets/images/about/workspace.jpg"
          layout="fullWidth"
          alt="Alt text"
          placeholder="blurred"
        />
      }
    />
    <AboutUs />
    <Leadership />
    <Clients />
    <Principles />
  </>
)

export default About
