import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import RichText from 'components/RichText'
import Heading from 'components/Heading'
import TeamMember from 'components/about/TeamMember'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import { Grid, GridRow, GridColumn } from 'components/Grid'

const Leadership = () => (
  <Section hasBorder>
    <Wrapper size="narrow">
      <SectionBlock>
        <Heading size={2}>Leadership</Heading>
        <RichText>
          <p>
            We're led by a duo of seasoned experts providing significant owner
            involvement on all client projects.
          </p>
        </RichText>
      </SectionBlock>
      <SectionBlock>
        <Grid>
          <GridRow>
            <GridColumn xs={12} sm={6}>
              <TeamMember
                photo={
                  <StaticImage
                    src="../../assets/images/about/leadership/alex.jpg"
                    layout="fullWidth"
                    placeholder="blurred"
                    alt="Alt text"
                  />
                }
                name="Alexander Haniotis"
                role="Co-Founder & Design Director"
                bio="Alexander oversees all things design and provides invaluable front-end development expertise to the team. He is passionate about building quality software while bridging the gap between design and technology. Alexander previously held early design positions at Shopify and Affirm. "
              />
            </GridColumn>
            <GridColumn xs={12} sm={6}>
              <TeamMember
                photo={
                  <StaticImage
                    src="../../assets/images/about/leadership/frank.jpg"
                    layout="fullWidth"
                    placeholder="blurred"
                    alt="Alt text"
                  />
                }
                name="Francis Marineau"
                role="Co-Founder & Technical Director"
                bio="Francis is a full stack developer with 10+ years of experience leading software development teams. He brings a unique mix of product and technical expertise to the table. Francis previously founded and led development efforts as CTO of several startups."
              />
            </GridColumn>
          </GridRow>
        </Grid>
      </SectionBlock>
    </Wrapper>
  </Section>
)

export default Leadership
