import React from 'react'
import styled from 'styled-components'

import { largerThan } from 'styles/media'

import Heading from 'components/Heading'
import RichText from 'components/RichText'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import Perk from 'components/careers/Perk'
import PerkGrid from 'components/PerkGrid'
import CareersSidebar from './CareersSidebar'

import Refresh from 'assets/icons/refresh.svg'
import Bag from 'assets/icons/bag.svg'
import Trophy from 'assets/icons/trophy.svg'
import Scale from 'assets/icons/scale.svg'
import Bulb from 'assets/icons/bulb.svg'
import Checkmark from 'assets/icons/checkmark.svg'

const Inner = styled.div`
  position: static;

  ${largerThan.medium`
    display: flex;
    align-items: flex-start;
  `};
`

const Principles = () => (
  <Section id="principles">
    <SectionBlock>
      <Wrapper size="narrow">
        <Heading size={2}>Our guiding principles</Heading>
        <RichText>
          <p>
            We approach our work with clear a set of guiding principles that
            influence both our internal and client-facing processes.
          </p>
        </RichText>
      </Wrapper>
    </SectionBlock>
    <SectionBlock>
      <Wrapper>
        <Inner>
          <PerkGrid twoUp>
            <Perk
              icon={<Scale />}
              heading="Integrity"
              description="We're honest and committed to doing what's best for our clients and our company."
              direction="horizontal"
            />
            <Perk
              icon={<Refresh />}
              heading="Continuous improvement"
              description="We work to constantly perfect our craft and avoid complacency."
              direction="horizontal"
            />
            <Perk
              icon={<Bag />}
              heading="Ownership"
              description="We care about our work and reward our team members for taking ownership."
              direction="horizontal"
            />
            <Perk
              icon={<Bulb />}
              heading="Resourcefulness"
              description="We show initiative and find creative ways to get the job done."
              direction="horizontal"
            />
            <Perk
              icon={<Trophy />}
              heading="Boldness"
              description="We take considered risks and aren't afraid to push the envelope."
              direction="horizontal"
            />
            <Perk
              icon={<Checkmark />}
              heading="Simplicity"
              description="We strive to find solutions that are both simple and elegant."
              direction="horizontal"
            />
          </PerkGrid>
          <CareersSidebar />
        </Inner>
      </Wrapper>
    </SectionBlock>
  </Section>
)

export default Principles
